/* LOADER */
:root {
	--font-family: 'Julius Sans One', sans-serif;
	--color: #333;
}
.loader {
	margin: 100px auto;
	width: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.loader-text {
	font-size: 18px;
	font-family: var(--font-family);
	color: var(--color);
	text-align: center
}

/* Spinner */
.loader-spinner {
	width: 40px;
	display: flex;
	justify-content: space-around;
}

.loader-spinner div {
	width: 8px;
	height: 8px;
	background-color: var(--color);
	border-radius: 50%;
	animation: loader-spinner 0.5s cubic-bezier(0.19, 0.57, 0.3, 0.98) infinite alternate;
}

@keyframes loader-spinner {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-20px);
	}
}

.loader-spinner div:nth-of-type(1) {
	opacity: 0.6;
}

.loader-spinner div:nth-of-type(2) {
	opacity: 0.5;
	animation-delay: 0.1s;
}

.loader-spinner div:nth-of-type(3) {
	opacity: 0.4;
	animation-delay: 0.2s;
}

.loader-spinner div:nth-of-type(4) {
	opacity: 0.2;
	animation-delay: 0.3s;
}

