* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.page {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
.page-contact {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
    color: #000000;
}

li {
    list-style: none;
}

/*
СТИЛИЗАЦИЯ ШАПКИ
*/

header {
    height: 150px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    font-family: 'Julius Sans One', sans-serif;
    background: rgba(255, 255, 255, 0.2);
    background: #fff;
}

.header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 0 15%;
    align-items: center;
    font-weight: 700;
    font-size: 28px;
    overflow: hidden;
}

.header .name {
    text-shadow: 0 0 20px 5px rgba(#000, 0.3);
    letter-spacing: 7.5px;
}

.header .name a {
    text-decoration: none;
    color: #000;
}

.header .name a:hover {
    text-decoration: none;
    color: #000;
}

.header .logo img {
    width: auto;
    height: 90px;
    margin-top: 7px;
    margin-bottom: 3px;
}

nav {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #000;
    height: 50px;
    font-size: 16px;
}

nav div {
    margin: 0 50px;
    font-weight: 600
}

nav div a {
    text-decoration: none;
    color: #000000;
    letter-spacing: 5px;
}

nav div a:hover {
    text-decoration: none;
    color: blue;
}
nav .mobMenu {
    display: none;
}
/*
СТИЛИЗАЦИЯ КОНТЕНТА 
ГЛАВНАЯ СТРАНИЦА
*/
.home {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    height: 100vh
}

.slider {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.slider img {
    width: 100%;
    height: auto;
}


/*
СТИЛИЗАЦИЯ ГАЛЕРЕИ
*/
.gallery-page {
    width: 100%;
    height: 100%
}
.gallery-page .gallery {
    width: 1580px;
    column-count: 5;
    column-gap: 20px;
    row-gap: 20px;
    margin: 50px auto;
}

.gallery-page .gallery .item {
    position: relative
}

.gallery-page .gallery .item img {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    margin-top: 5px;
    border-radius: 5px;
    max-width: 100%
}

.gallery-page .gallery .item .info {
    position: absolute;
    width: 100%;
    margin: 10px auto;
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 700;
    text-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.3);
}

.gallery-page .gallery .item .info h2 {
    text-align: center;
    margin: 15px auto;
    width: 100%;
    height: auto;
    font-size: 20px;
    letter-spacing: 3px;
}

.gallery-page .gallery .item .info div {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-bottom: 15px;
}

.gallery-page .gallery .item .info p {
    margin: 0 10px 0 0;
}

.gallery-page .category {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gallery-page .category {
    margin: 30px auto;
}

.gallery-page .category button {
    font-family: 'Julius Sans One', sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 3px;
    margin: 5px 10px;
    background: #fff;
    outline: none;
    border: none;
    border-radius: 3px;
    color: #535353;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    text-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.3);
    padding: 7px 15px 5px 15px;
}

.gallery-page .category button:hover {
    background: rgba(0, 0, 0, 0.03);
}

.bigImg {
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100vh;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
}
.bigImg img {
    margin-top: 12.5vh;
    height: 80vh;
    width: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0,  0.8);

}

/*
СТИЛИЗАЦИЯ CV
*/

.cv-page {
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #000;
    text-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.3);
}

.cv-page .cv {
    text-align: left;
    margin: 50px auto;
    width: 50%;
}

.cv-page .cv p {
    margin: 10px auto;
}
.cv-page .cv p span {
    font-weight: 700;
}

/*
	СТИЛИЗАЦИЯ ОБО МНЕ
*/
.texts-page {
    margin: 25px auto;
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 400;
}

.texts-page article {
    margin: 0 20%;
}

.texts-page article p{
    text-indent: 50px;
    font-size: 16px;
    text-align: justify;
    line-height: 27.5px;
}
.texts-page article p b{
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.texts-page article p::first-letter {
    font-weight: 700;
    font-size: 18px;
}

.texts-page article .main {
    display: flex;
    justify-content: center;
}

.texts-page article .main div {
    width: 45%;
    height: 45%;
    margin: 0 auto 25px auto;
}
.texts-page article .main div h1{
    letter-spacing: 4px;
    text-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
}

.texts-page article .main img {
    width: 45%;
    height: 45%;
    margin: 25px 25px 25px 50px;
    border-radius: 5px;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
}
.texts-page article section {
    display: flex;
    flex-direction: column;
    font-family: 'Julius Sans One', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding-left: 25px;
    padding-top: 25px;
}
.texts-page article section h4 {
    font-size: 18px;  
}
.texts-page article section a {
    margin-top: 10px;  
    color: #535353;
}
.texts-page article section a:hover {
    color: #0000FF;
}

/*
	СТИЛИЗАЦИЯ СОНТАКТОВ
*/
.contact-page {
    display: flex;
    justify-content: center;
    padding-top: 2.5%;
}

.contact-page h1 {
    letter-spacing: 4px;
    text-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 700;
}
.contact-page .contact {
    width: 20%;
    text-align: center;
    padding-top: 12.5px;
}
.contact-page .contact form {
    width: 100%;
    margin: auto;
}

.contact-page .contact input,
textarea {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    margin: 5px auto;
    font-size: 14px;
    color: #0000FF;
    border: none;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3)
}

.contact-page .contact input:focus, textarea:focus {
    outline: none;
}

.contact-page .contact button {
    width: 150px;
    height: 30px;
    line-height: 29px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.3);
    margin: 10px auto 0 auto;
    letter-spacing: 4px;
    text-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 700;
}

.contact-page .success {
    display: none;
}

/*
	СТИЛИЗАЦИЯ ПОДВАЛА
	*/

footer {
    margin-top: 25px;
    position: relative;
    bottom: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Julius Sans One', sans-serif;
    font-size: 32px;
    background: #fff;
}

footer p {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 5px;
}

footer p a {
    text-decoration: none;
    color: #000000;
}

footer p a:hover {
    text-decoration: none;
    color: #000000;
}

footer .soc {
    display: flex;
    margin-left: 25px;
}

footer .soc div {
    margin-left: 15px;
}

footer .soc div:hover {
    color: #535353
}
/* .footer-home, .footer-contact{
    position: absolute;
    bottom: -60px;
    padding-bottom: 60px;
} */
.footer-home {
    position: absolute;
} 
.footer-contact{
   margin-top: auto;
   margin-bottom: 0;
} 



@media screen and (max-width: 1620px) {
    /*
    GALLERY
    */
    .gallery-page .gallery {
        width: 1260px;
        column-count: 4;
    }
}
@media screen and (max-width: 1366px) {
    /*
        GALLERY
    */
    .gallery-page .gallery {
        width: 930px;
        column-count: 3;
    }
     /*
        TEXTS
    */
    .texts-page article {
        margin: 0 15%;
    }
    .texts-page article .main {
        flex-direction: column;
    }
    .texts-page article .main div {
        width: 100%;
    }
    .texts-page article .main div h1 {
        text-align: center;
    }
    .texts-page article .main img {
        width: 75%;
        height: auto;
        margin: 25px auto;
    }

    /*
        CONTACT ME
    */
    .contact-page .contact {
        width: 30%;
    }
    .contact-page h1 {
        font-size: 20px;
    }

}





@media screen and (max-width: 992px) {
    /*
    HOME
    */
    header {
        height: 115px;
        background: rgba(255, 255, 255, 0.2);
        background: #fff;
    }
    .header {
        height: 75px;
        padding: 0 5%;
        font-size: 22px;
    }
    .header .name {
        letter-spacing: 5px;
    }
    .header .logo img {
        height: 65px;
        margin-top: 7px;
        margin-bottom: 3px;
    }
    .slider img {
        overflow: hidden;
        width: auto;
        height: 100vh;
    }
    nav {
        height: 40px;
        font-size: 12px;
    }
    nav div {
        margin: 0 30px;
        font-weight: 600
    }
    nav div a {
        letter-spacing: 2px;
    }
    footer {
        margin-top: 50px;
        height: 40px;
        font-size: 24px;
    }
    footer p {
        font-size: 14px;
        letter-spacing: 2px;
    }
    footer .soc {
        align-items: center;
    }
    footer .soc img{
        width: 24px;
        height: auto;
        margin-top: 4px;
    }
    
    /*
    GALLERY
    */
    .gallery-page .gallery {
        width: 650px;
        column-count: 3;
    }
    .gallery-page .gallery .item, img {
        width: 210px;
        height: auto;
    }
    
    /*
    CV
    */
    
    .cv-page {
        font-size: 14px;
    }
    .cv-page .cv {
        margin: 25px auto;
        width: 70%;
    }
    /*
        TEXTS
    */
    .texts-page article {
        margin: 0 10%;
    }

    .texts-page article .main {
        flex-direction: column;
    }

    .texts-page article .main div {
         width: 90%;
    }

    .texts-page article .main div h1 {
        text-align: center;
        font-size: 21px
    }

    .texts-page article .main img {
        width: 75%;
        height: auto;
        margin: 25px auto;
    }
    .texts-page article p {
        font-size: 14px;
        text-align: justify;
        line-height: 27.5px;
    }
    .texts-page article p b {
        font-size: 13px;
        letter-spacing: 1px;
        margin-bottom: 5px;
    }
    .texts-page article section h4 {
        font-size: 16px;
    }
    .texts-page article section {
        font-size: 14px;
        padding: 25px;
    }
    /*
        CONTACT ME
    */
    .contact-page .contact {
        width: 45%;
    }

    .contact-page h1 {
        font-size: 20px;
    }
}
@media screen and (max-width: 680px) {
    
    /*
    HOME
    */
    header {
        height: 100px;
        background: rgba(255, 255, 255, 0.2);
        background: #fff;
    }

    .header {
        height: 60px;
        padding: 0 5%;
        font-size: 18px;
    }
    .header .name {
        letter-spacing: 3px;
    }
    .header .logo img {
        height: 50px;
        margin-top: 7px;
        margin-bottom: 3px;
    }
    nav {
        height: 40px;
        font-size: 12px;
    }
    nav div {
        margin: 0 25px;
        font-weight: 600
    }
    footer {
        height: 30px;
        font-size: 18px;
    }
    footer p {
        font-size: 10px;
        letter-spacing: 2px;
    }
    footer .soc img {
        width: 18px;
        margin-top: 2px;
    }


       
    /*
        GALLERY
    */
    .gallery-page .gallery {
       width: 440px;
       column-count: 2;
   }

    .gallery-page .gallery .item, img {
        width: 210px;
        height: auto;
    }

    /*
        CV
    */

    .cv-page {
        font-size: 12px;
    }
    .cv-page .cv {
        width: 80%;
    }

    /*
        TEXTS
    */
    .texts-page {
        margin: 10px auto;
    }
    .texts-page article {
        margin: 0 5%;
    }

    .texts-page article .main div {
        width: 100%;
        margin: 0 auto 10px auto;
    }

    .texts-page article .main div h1 {
        font-size: 16px
    }

    .texts-page article .main img {
        width: 90%;
        margin: 10px auto;
    }

    .texts-page article p {
        font-size: 10px;
        line-height: 15px;
        text-indent: 25px;
    }

    .texts-page article p b {
        font-size: 11px;
        margin-bottom: 1px;
    }

    .texts-page article section h4 {
        font-size: 14px;
    }

    .texts-page article section {
        font-size: 11px;
    }
    .texts-page article section h4 {
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 0;
    }
    .texts-page article section a {
        margin-top: 7.5px;
        color: #535353;
    }

    /*
        CONTACT ME
    */
    .contact-page .contact {
        margin-top: 5%;
        width: 70%;
    }

    .contact-page h1 {
        font-size: 20px;
    }
}
@media screen and (max-width: 515px) {

    /*
        HOME
    */
    .header {
        height: 40px;
        padding: 0 5%;
        font-size: 16px;
    }
    .header .name {
        letter-spacing: 2px;
    }
    .header .logo img {
        height: 30px;
        margin-top: 7px;
        margin-bottom: 3px;
    }
    nav{
        height: 60px;
    }
    nav div {
        display: none;
    }
    nav .mobMenu {
        font-size: 28px;
        display: block;
    }
    .active .mobMenu {
        display: none;
    }
    .active {
        /* nav  in  mobile  version */
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 25%;
    }

    .active div {
        display: block;
        font-size: 20px;
        margin: 4% auto;
        font-weight: 600
    }
    footer {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    footer .soc {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-top: -5px;
    }
    footer .soc div {
        margin: auto 7.5px;
    }

    /*
        GALLERY
    */
    .gallery-page .gallery {
        width: 330px;
        column-count: 2;
        column-gap: 10px;
    }

    .gallery-page .gallery .item, img {
        width: 160px;
        height: auto;
    }
    .gallery-page .gallery .item .info {
        margin-top: 5%;
        width: 160px;
    }

    .gallery-page .gallery .item .info h2 {
        margin: 5px auto 10px auto;
        font-size: 12px;
        letter-spacing: 1px;
    }

    .gallery-page .gallery .item .info div {
        font-size: 8px;
        margin-bottom: 7.5px;
    }

    /*
        CV
    */

    .cv-page {
        font-size: 10px;
    }
    .cv-page .cv {
        width: 90%;
    }

}
@media screen and (max-width: 320px) {
     /*
        GALLERY
    */
     .gallery-page .gallery {
         width: 290px;
         column-count: 2;
         column-gap: 10px;
     }

     .gallery-page .gallery .item, img {
         width: 140px;
         height: auto;
     }

    .gallery-page .gallery .item .info {
        margin-top: 5%;
        width: 140px;
    }

    .gallery-page .gallery .item .info h2 {
        margin: 5px auto 10px auto;
        font-size: 10px;
        letter-spacing: 1px;
    }

    .gallery-page .gallery .item .info div {
        font-size: 8px;
        margin-bottom: 7.5px;
    }

    /*
        CV
    */

    .cv-page {
        font-size: 9px;
    }

    /*
        CONTACT ME
    */
    .contact-page .contact {
        width: 80%;
    }

    .contact-page h1 {
        font-size: 16px;
    }
}